<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0" y="0" width="19.787" height="21.222" viewBox="0, 0, 19.787, 21.222">
    <g id="Layer_1">
      <path d="M18.528,6.346 L17.906,5.265
      C17.379,4.352 16.212,4.036 15.297,4.561 L15.297,4.561
      C14.861,4.817 14.342,4.89 13.852,4.763
      C13.363,4.636 12.944,4.319 12.688,3.883
      C12.524,3.606 12.435,3.29 12.432,2.968 L12.432,2.968
      C12.447,2.451 12.252,1.95 11.892,1.58
      C11.531,1.209 11.036,1 10.519,1 L9.265,1
      C8.758,1 8.273,1.202 7.916,1.561
      C7.558,1.92 7.359,2.407 7.361,2.913 L7.361,2.913
      C7.346,3.959 6.494,4.799 5.448,4.799
      C5.126,4.795 4.81,4.707 4.533,4.542 L4.533,4.542
      C3.618,4.018 2.451,4.333 1.924,5.247 L1.256,6.346
      C0.73,7.258 1.041,8.425 1.952,8.954 L1.952,8.954
      C2.543,9.296 2.908,9.927 2.908,10.611
      C2.908,11.295 2.543,11.926 1.952,12.268 L1.952,12.268
      C1.042,12.794 0.731,13.957 1.256,14.867 L1.256,14.867 L1.887,15.956
      C2.134,16.402 2.548,16.73 3.038,16.869
      C3.527,17.008 4.052,16.947 4.496,16.698 L4.496,16.698
      C4.933,16.443 5.453,16.373 5.941,16.504
      C6.429,16.635 6.845,16.955 7.096,17.394
      C7.26,17.671 7.349,17.987 7.352,18.309 L7.352,18.309
      C7.352,19.366 8.208,20.222 9.265,20.222 L10.519,20.222
      C11.572,20.222 12.427,19.371 12.432,18.318 L12.432,18.318
      C12.43,17.81 12.63,17.322 12.99,16.963 C13.349,16.603 13.837,16.403 14.345,16.405
      C14.667,16.414 14.981,16.502 15.26,16.661 L15.26,16.661
      C16.173,17.187 17.339,16.876 17.869,15.966 L17.869,15.966 L18.528,14.867
      C18.783,14.429 18.853,13.908 18.723,13.418
      C18.592,12.929 18.272,12.511 17.832,12.259 L17.832,12.259
      C17.393,12.006 17.073,11.589 16.942,11.099 C16.812,10.609 16.882,10.088 17.137,9.65
      C17.303,9.36 17.543,9.12 17.832,8.954 L17.832,8.954
      C18.738,8.425 19.048,7.265 18.528,6.355 L18.528,6.355 L18.528,6.346 z"
      fill-opacity="0" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.53,10.613 C12.53,12.069 11.35,13.249 9.894,13.249
      C8.438,13.249 7.258,12.069 7.258,10.613 C7.258,9.157 8.438,7.977 9.894,7.977
      C11.35,7.977 12.53,9.157 12.53,10.613 z"
      fill-opacity="0" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Settings',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
