<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0" y="0" width="17" height="17" viewBox="0, 0, 17, 17"
  >
    <g id="Layer_1">
      <g id="Chart">
        <path
          d="M12.471,7.648 L12.471,12.793"
          fill-opacity="0" :stroke="color" stroke-width="1.5"
          stroke-linecap="round" stroke-linejoin="round"
        />
        <path
          d="M8.971,5.188 L8.971,12.794"
          fill-opacity="0" :stroke="color" stroke-width="1.5"
          stroke-linecap="round" stroke-linejoin="round"
        />
        <path
          d="M5.533,10.375 L5.533,12.801"
          fill-opacity="0" :stroke="color" stroke-width="1.5"
          stroke-linecap="round" stroke-linejoin="round"
        />
        <path
          d="M5.486,1.5 L12.514,1.5 C14.964,1.5 16.5,3.234 16.5,5.689 L16.5,12.311
          C16.5,14.766 14.971,16.5 12.514,16.5 L5.486,16.5
          C3.029,16.5 1.5,14.766 1.5,12.311 L1.5,5.689
          C1.5,3.234 3.029,1.5 5.486,1.5 z"
          fill-opacity="0" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Chart',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
