const menu = [
  {
    icon: 'Chart',
    title: 'Portfolio',
    route: '/portfolio',
    i18nPath: 'views.Portfolio',
  },
  {
    icon: 'Search',
    title: 'Marketplace',
    route: '/marketplace',
    i18nPath: 'views.Marketplace',
  },
  {
    icon: 'Profile',
    title: 'Profile',
    route: '/profile',
    i18nPath: 'views.Profile.title',
  },
  {
    icon: 'Settings',
    title: 'Settings',
    route: '/settings',
    i18nPath: 'views.Settings',
  },
  {
    icon: 'Call',
    title: 'Support',
    route: 'https://www.cometum.com/de/support',
    i18nPath: 'views.Support',
  },
  {
    icon: 'Logout',
    title: 'Logout',
    route: '/logout',
    i18nPath: 'views.Logout',
  },
];

export default menu;
