<template>
  <v-navigation-drawer
    app
    clipped
    color="element"
  >
    <v-list dense nav class="d-flex flex-column justify-space-between h-full">
      <div class="mt-15">
        <v-list-item
          color="primary"
          v-for="(item, index) in topMenu"
          :key="index"
          :to="item.route"
        >
          <v-list-item-icon class="ml-3 mr-3 mt-2">
            <component
              :is="item.icon"
              :color="$vuetify.theme.dark ? '#ffffff' : '#000000'"
              class="menu-icon"
            />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="defaultText--text">{{ $t(item.i18nPath) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div class="mb-5">
        <v-list-item
          color="primary"
          v-for="(item, index) in downMenu"
          :key="index"
          :to="item.title !== 'Support' ? item.route : null"
          :href="item.title === 'Support' ? item.route : null"
          :target="item.title === 'Support' ? '_blank' : null"
        >
          <v-list-item-icon class="ml-3 mr-3">
            <component
              :is="item.icon"
              :color="$vuetify.theme.dark ? '#ffffff' : '#000000'"
              class="menu-icon"
            />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="defaultText--text">
              {{ $t(item.i18nPath) }}
              <v-icon v-if="item.title === 'Support'" size="10" class="ml-1">
                mdi-arrow-top-right
              </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import menu from '@/config/menu';
import clone from '@/utils/clone';
import Call from '@/assets/icons/svg/Call.vue';
import Chart from '@/assets/icons/svg/Chart.vue';
import Logout from '@/assets/icons/svg/Logout.vue';
import Profile from '@/assets/icons/svg/Profile.vue';
import Search from '@/assets/icons/svg/Search.vue';
import Settings from '@/assets/icons/svg/Settings.vue';

export default {
  name: 'SideMenu',
  components: {
    Call,
    Chart,
    Logout,
    Profile,
    Search,
    Settings,
  },
  computed: {
    ...mapGetters('auth', [
      'roles',
    ]),
    topMenu() {
      return clone(menu).splice(0, 3);
    },
    downMenu() {
      return clone(menu).splice(3, 4);
    },
  },
};
</script>

<style lang="scss" scoped>

.v-list-item {
  text-decoration: none !important;
  margin: 0 -8px;
  &:before {
    border-radius: 0px !important;
    opacity: 0;
  }
  &--active {
    border-radius: 0px !important;
    background-color: var(--v-backgroundLight-base);
  }
}
.menu-icon {
  transform: scale(0.8);
  margin-top: 3px;
}
</style>
