<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0"
  width="16.997" height="17.372" viewBox="0, 0, 16.997, 17.372">
    <g id="Layer_1">
      <g id="Search">
        <path d="M15.87,8.685 C15.87,12.653 12.653,15.87 8.685,15.87
        C4.717,15.87 1.5,12.653 1.5,8.685 C1.5,4.717 4.717,1.5 8.685,1.5
        C12.653,1.5 15.87,4.717 15.87,8.685 z"
        fill-opacity="0" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.68,14.062 L16.497,16.872" fill-opacity="0"
        :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Search',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
