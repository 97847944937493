<template>
  <svg
    version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0" y="0" width="17.072" height="16.646" viewBox="0, 0, 17.072, 16.646"
  >
    <g id="Layer_1">
      <g id="Logout">
        <path d="M11.193,5.156 L11.193,4.417
        C11.193,2.806 9.887,1.5 8.276,1.5 L4.417,1.5
        C2.806,1.5 1.5,2.806 1.5,4.417 L1.5,13.229
        C1.5,14.84 2.806,16.146 4.417,16.146 L8.284,16.146
        C9.89,16.146 11.193,14.844 11.193,13.237 L11.193,12.491"
        fill-opacity="0" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.572,8.826 L7.039,8.826" fill-opacity="0"
        :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.25,6.516 L16.568,8.823 L14.25,11.132"
        fill-opacity="0" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logout',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
